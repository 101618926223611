<template>

<div class="profile" v-tooltip="(hasConventions || isAdmin) ? 'Click to manage profile' : false" v-on:click="onClick" :class="{'is-empty': !hasConventions && !isAdmin}">

	<div class="profile-avatar" v-if="avatar" v-bgimage="avatar"></div>
	<app-icon icon="user" class="profile-icon" v-if="!avatar" />

	<div class="profile-about">
		<div class="profile-about-name">{{ name }}</div>
		<div class="profile-about-badge" v-if="badge && $convention.settings.badges_numbers_public">Badge #{{ badge.reference }}</div>
		<div class="profile-about-badge" v-if="badge && !$convention.settings.badges_numbers_public">Badge Ref. {{ badge.reference }}</div>
	</div>

	<app-icon icon="caret-down" class="profile-caret" v-if="!expanded" />
	<app-icon icon="close" class="profile-caret" v-if="expanded && (hasConventions || isAdmin)" />

</div>

</template>

<script>

export default {

	props: ['expanded'],

	computed: {

		isAdmin: function() {

			return process.env.VUE_APP_TYPE === 'admin'

		},

		hasConventions: function() {

			return this.$store.getters['session/conventions'].length

		},

		avatar: function() {

			return this.$store.getters['session/avatar']

		},

		name: function() {

			return this.$store.getters['session/name']

		},

		badge: function() {

			return this.$store.getters['session/badge']

		}

	},

	methods: {

		onClick: function() {

			if (this.hasConventions || this.isAdmin) {

				this.$emit('toggleAccount')

			}

		}

	}

}

</script>

<style scoped>

.profile {
	padding: 20px 20px;
	width: 320px;
	display: flex;
	flex-shrink: 0;
	background-color: #21262e;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.profile.is-empty {
	background-color: #2f3a4a;
	pointer-events: none;
}

.is-device .profile {
	width: 100%;
}

.profile-avatar {
	border-radius: 50%;
	background-color: #fff;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	background-position: 50% 50%;
	background-size: cover;
	flex-shrink: 0;
}

.profile-icon {
	width: 40px;
	height: 40px;
	color: #333;
	margin-right: 20px;
	text-align: center;
	line-height: 40px;
	font-size: 20px;
	flex-shrink: 0;
	border-radius: 50%;
	background-color: #fff;
}

.profile-about {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.profile-about-name {
	color: #fff;
	font-size: 20px;
	letter-spacing: 1px;
	font-weight: 400;
}

.profile-about-badge {
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	letter-spacing: 1px;
	margin-top: 1px;
	font-weight: 400;
}

.profile-button {
	width: 80px;
	flex-shrink: 0;
}

.profile-caret {
	color: #fff;
	font-size: 16px;
}

</style>
